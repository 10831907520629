import BodyText from 'firebird-ui/src/components/BodyText'
import styled from 'styled-components'

export const Container = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  column-gap: 32px;
  row-gap: 24px;

  ${p => p.theme.mediaQueries.mobileTablet} {
    grid-template-columns: 100%;
  }

  ${p => p.theme.mediaQueries.mobile} {
    row-gap: 16px;
  }
`

export const Description = styled(BodyText)`
  * {
    color: ${p => p.theme.colors.Surface100.$value};
  }

  ${p => p.theme.mediaQueries.desktopXS} {
    max-width: 100%;
  }
`

export const List = styled.ul`
  display: flex;
  width: 100%;
  list-style-type: none;

  ${p => p.theme.mediaQueries.mobile} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`
